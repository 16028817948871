import { useContext } from "react";
import {
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  TitleFieldProps
} from "@rjsf/utils";
import { Heading } from "@chakra-ui/react";
import { FormViewerContext } from "../context";
import { getPathOr } from "crocks";

export default function TitleField<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({ id, title }: TitleFieldProps<T, S, F>) {
  const { styles } = useContext(FormViewerContext);
  const headingStyles = getPathOr({}, ["heading"], styles);

  if (!title || title === "<empty_value>") {
    return null;
  }

  return (
    <Heading as="h5" fontFamily={styles.form.fontFamily} {...headingStyles}>
      {title}
    </Heading>
  );
}
