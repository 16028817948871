import { extendTheme, theme as chakraTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  components: {
    Input: {
      variants: {
        outline: {
          field: {
            background: "white"
          }
        }
      }
    }
  },
  styles: {
    global: {
      ".leadpal-form-root": {
        WebkitFontSmoothing: "antialiased", // Antialiasing.
        MozOsxFontSmoothing: "grayscale", // Antialiasing.
        // Change from `box-sizing: content-box` so that `width`
        // is not affected by `padding` or `border`.
        boxSizing: "border-box",
        fontSize: 14,
        color: chakraTheme.colors.gray["500"]
      },
      ".firebase-emulator-warning": { display: "none" },
      "-ms-overflow-style": "none"
    }
  },
  letterSpacings: {
    normal: "0.03em"
  },
  colors: {
    current: chakraTheme.colors.gray,
    error: chakraTheme.colors.red["500"],
    brand: {
      body: chakraTheme.colors.gray,
      buttonPrimary: chakraTheme.colors.blue,
      buttonSecondary: chakraTheme.colors.gray
    }
  }
});
