import { useContext } from "react";
import {
  helpId,
  FieldHelpProps,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema
} from "@rjsf/utils";
import { FormHelperText } from "@chakra-ui/react";
import { FormViewerContext } from "../context";
import getPathOr from "crocks/helpers/getPathOr";

/** The `FieldHelpTemplate` component renders any help desired for a field
 *
 * @param props - The `FieldHelpProps` to be rendered
 */
export default function FieldHelpTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: FieldHelpProps<T, S, F>) {
  const { styles } = useContext(FormViewerContext);
  const formStyles = getPathOr({}, ["form"], styles);
  const helpStyles = getPathOr({}, ["inputHelpText"], styles);
  const { idSchema, help } = props;

  if (!help) {
    return null;
  }
  const id = helpId<T>(idSchema);
  return (
    <FormHelperText
      id={id}
      {...helpStyles}
      color={formStyles.color}
      marginBottom={4}
    >
      {help}
    </FormHelperText>
  );
}
