import { useState, useEffect } from "react";
import { FormViewer } from "./components/form_viewer/viewer";
import { ChakraProvider, Box, Stack } from "@chakra-ui/react";
import { db } from "./firebase";
import { getDoc, doc } from "firebase/firestore";
import { theme } from "./theme";
import gl from "./glamorous.svg";

const LeadpalViewer = ({ children, form }) => {
  const bgColor = form?.styles?.form?.backgroundColor || "white";

  return (
    <Stack
      alignItems="center"
      justifyContent={{ base: "flex-start", md: "center" }}
      minHeight="100vh"
      minWidth="100vw"
      backgroundColor="#ece5dd"
      backgroundImage={`url(${gl})`}
    >
      <Box
        padding={{ base: 4, md: 8 }}
        background={bgColor}
        borderRadius="md"
        shadow="lg"
        border="1px solid"
        borderColor="gray.200"
        width={{ base: "100%", md: "50%" }}
        maxWidth={{ base: "inherit", md: "680px" }}
      >
        {children}
      </Box>
    </Stack>
  );
};

const DefaultViewer = ({ children }) => children;

const appContainers = {
  leadpal_viewer: LeadpalViewer,
  embedded: DefaultViewer,
  default: DefaultViewer
};

function App({ formId, options = {} }) {
  const [form, setForm] = useState(null);
  const [exists, setExists] = useState(true);
  const { mode = "embedded" } = options;

  useEffect(() => {
    getDoc(doc(db, "forms", formId))
      .then(snap => {
        const data = snap.data();
        setExists(snap.exists() && data.status !== "deleted");
        return data;
      })
      .then(setForm);
  }, [formId]);

  if (!exists) {
    return <Box>This form does not exist</Box>;
  }

  if (!form) {
    return null;
  }

  const Container = appContainers[mode] || appContainers.default;

  return (
    <ChakraProvider theme={theme}>
      <div className="leadpal-form-root">
        <Container form={form}>
          <FormViewer
            formId={formId}
            schema={form.schema}
            uiSchema={form.ui_schema}
            styles={form.styles}
            confirmation={form.confirmation}
          />
        </Container>
      </div>
    </ChakraProvider>
  );
}

export default App;
