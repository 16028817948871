import { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Heading,
  Text as ChakraText,
  OrderedList,
  UnorderedList,
  ListItem,
  Link
} from "@chakra-ui/react";
import validator from "@rjsf/validator-ajv8";
import { FormViewerContext } from "./context";
import { withTheme } from "@rjsf/core";
import { Theme as ChakraUITheme } from "@rjsf/chakra-ui";
import SelectWidget from "./SelectWidget";
import BaseInputTemplate from "./BaseInputTemplate";
import SubmitButton from "./SubmitButton";
import TitleField from "./TitleField";
import DescriptionField from "./DescriptionField";
import FieldTemplate from "./FieldTemplate";
import ObjectFieldTemplate from "./ObjectFieldTemplate";
import FieldHelpTemplate from "./FieldHelpTemplate";
import escapeHtml from "escape-html";
import { Text } from "slate";
import { saveEntry } from "../../googleFunctions";
import { format } from "date-fns/fp";
import compose from "crocks/helpers/compose";
import identity from "crocks/combinators/identity";
import getPathOr from "crocks/helpers/getPathOr";
import getPropOr from "crocks/helpers/getPropOr";

const ChakraForm = withTheme(ChakraUITheme);

const processTextNode = node => {
  let string = node.text;
  let output = <>{string}</>;

  /* @ts-ignore */
  if (node.bold) {
    output = <Box as="strong">{output}</Box>;
  }

  /* @ts-ignore */
  if (node.italic) {
    output = <Box as="em">{output}</Box>;
  }

  /* @ts-ignore */
  if (node.underline) {
    output = (
      <Box as="span" textDecoration="underline">
        {output}
      </Box>
    );
  }

  return output;
};

const serialize = node => {
  const { align = "left" } = node;
  /* @ts-ignore */
  if (Text.isText(node)) {
    return processTextNode(node);
  }

  const children = node.children.map(n => serialize(n));

  switch (node.type) {
    case "heading-one": {
      return (
        <Heading as="h1" textAlign={align}>
          {children}
        </Heading>
      );
    }

    case "heading-two": {
      return (
        <Heading as="h2" textAlign={align}>
          {children}
        </Heading>
      );
    }

    case "numbered-list": {
      return (
        <OrderedList paddingLeft={8} textAlign={align}>
          {children}
        </OrderedList>
      );
    }

    case "bulleted-list": {
      return (
        <UnorderedList paddingLeft={4} textAlign={align}>
          {children}
        </UnorderedList>
      );
    }

    case "list-item": {
      return <ListItem textAlign={align}>{children}</ListItem>;
    }

    case "quote":
      return (
        <Box as="blockquote">
          <ChakraText textAlign={align}>{children}</ChakraText>
        </Box>
      );

    case "paragraph": {
      return <ChakraText textAlign={align}>{children}</ChakraText>;
    }

    case "link": {
      return (
        <Link href={escapeHtml(node.url)} textAlign={align}>
          {children}
        </Link>
      );
    }

    default:
      return children;
  }
};

const handleSubmit = (formId, tracking_params, options) => e =>
  saveEntry({
    formId,
    entry: e.formData,
    referrer: document.referrer,
    tracking_params,
    options
  });

const Confirmation = ({ confirmation, formData }) => {
  const { type, url, include_querystring } = confirmation;

  useEffect(() => {
    if (type !== "url") {
      return;
    }

    const redirectUrl = new URL(url);

    if (include_querystring) {
      redirectUrl.search = new URLSearchParams(formData).toString();
    }

    window.location.href = redirectUrl.toString();
  }, [formData, include_querystring, type, url]);

  if (type === "url") {
    return null;
  }

  return <Stack spacing={2}>{confirmation.nodes.map(serialize)}</Stack>;
};

const qsFormatters = {
  date: compose(format("yyyy-MM-dd"), v => new Date(v)),
  identity
};

const getTrackingParams = () => {
  const qsObj = Object.fromEntries(new URLSearchParams(window.location.search));

  const localParams = JSON.parse(
    window.localStorage.getItem("leadpal_forms_search_params") || "[]"
  );

  // If customer is using localStorage we can assume that the current
  // qs values have been stored there.
  return (localParams.length > 0 ? localParams : [qsObj]).filter(
    obj => Object.keys(obj).length > 0
  );
};

export const FormViewer = ({
  formId,
  schema,
  uiSchema,
  styles,
  confirmation,
  options
}) => {
  const [formDataFromSubmit, setFormData] = useState(null);
  const [showConfirmation, setShowConfirmaiton] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { form } = styles;

  const widgets = {
    SelectWidget
  };

  const templates = {
    ObjectFieldTemplate,
    FieldTemplate,
    FieldHelpTemplate,
    BaseInputTemplate,
    TitleFieldTemplate: TitleField,
    DescriptionFieldTemplate: DescriptionField,
    ButtonTemplates: {
      SubmitButton
    }
  };

  // Populate fields from querystring
  const qs = new URLSearchParams(window.location.search);

  const formData = Object.entries(uiSchema["ui:autofill"] || {}).reduce(
    (agg, [k, v]) => {
      const format = getPropOr(
        qsFormatters.identity,
        getPropOr(
          "invalid",
          "format",
          getPathOr({}, ["properties", k], schema)
        ),
        qsFormatters
      );

      return {
        ...agg,
        [k]: format(qs.get(v as string))
      };
    },
    {}
  );

  return (
    <FormViewerContext.Provider
      value={{
        styles,
        isSubmitting
      }}
    >
      <Box {...form} maxWidth="600px">
        {showConfirmation ? (
          <Confirmation
            confirmation={confirmation}
            formData={formDataFromSubmit}
          />
        ) : (
          <ChakraForm
            formData={formData}
            schema={schema}
            uiSchema={uiSchema}
            validator={validator}
            widgets={widgets}
            templates={templates}
            onSubmit={e => {
              setIsSubmitting(true);
              const trackingParams = getTrackingParams();
              handleSubmit(
                formId,
                trackingParams,
                options
              )(e).then(res => {
                // Raise window event for user to subscribe to
                // Useful for tracking events in Google etc.
                try {
                  window.postMessage(
                    {
                      type: "leadpal-form-success",
                      eventName: "formSubmitSuccess",
                      formId,
                      entryId: res.data,
                      trackingParams
                    },
                    window.origin
                  );
                } catch (e) {}

                setIsSubmitting(false);
                setFormData(e.formData);
                setShowConfirmaiton(true);
              });
            }}
            noHtml5Validate
          />
        )}
      </Box>
    </FormViewerContext.Provider>
  );
};
