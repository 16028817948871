import { Stack, Button } from "@chakra-ui/react";
import {
  FormContextType,
  getSubmitButtonOptions,
  RJSFSchema,
  StrictRJSFSchema,
  SubmitButtonProps
} from "@rjsf/utils";
import { useContext } from "react";
import { FormViewerContext } from "../context";

export default function SubmitButton<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({ uiSchema }: SubmitButtonProps<T, S, F>) {
  const {
    submitText,
    norender,
    props: submitButtonProps
  } = getSubmitButtonOptions(uiSchema);
  const { styles, isSubmitting } = useContext(FormViewerContext);
  const { submitButton } = styles;

  const {
    alignItems,
    width = "fit-content",
    textAlign = "left",
    ...styleProps
  } = submitButton;

  if (norender) {
    return null;
  }

  return (
    <Stack marginTop={3} alignItems={alignItems}>
      <Button
        isLoading={isSubmitting}
        type="submit"
        variant="solid"
        height="auto"
        padding={2}
        {...submitButtonProps}
        width={width}
        textAlign={textAlign}
        {...styleProps}
      >
        {submitText}
      </Button>
    </Stack>
  );
}
