import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./sentry";
import * as Sentry from "@sentry/react";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const Error = () => <div>An error has occured loading this form.</div>;

window.lpal = {
  forms: {
    render: (formId, container, element, options) => {
      const root = ReactDOM.createRoot(element);
      const cache = createCache({ container, key: "lp" });

      root.render(
        <React.StrictMode>
          <Sentry.ErrorBoundary fallback={<Error />}>
            <CacheProvider value={cache}>
              <App formId={formId} options={options} />
            </CacheProvider>
          </Sentry.ErrorBoundary>
        </React.StrictMode>
      );
    }
  }
};
